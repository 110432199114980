import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-list bran-style-2">
                    <li>
                        <a href="https://www.youtube.com/channel/UCQxWQycVREjRt5O3Yc5cQ0g" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/brand/dlbd_new.png" alt="Doc La Binh Duong"/>
                        </a>                        
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCSVUw-eq8DkEh0rN4aOIm9w" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/brand/dlvn.png" alt="Doc La Viet Nam"/>
                        </a>                        
                    </li>

                    <li>
                        <a href="https://www.youtube.com/channel/UClAN8--dF1vOr8pJ6zPaN1g" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/brand/trainghiemmoi.png" alt="Trai Nghiem Moi"/>
                        </a>                        
                    </li>   

                    <li>
                        <a href="https://www.youtube.com/channel/UCXRc9h7_yqC-jBknVQPGUpA" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/brand/nhuPhu_vlog.png" alt="Nhu Phu Vlog"/>
                        </a>                        
                    </li>  

                    <li>
                        <a href="https://www.youtube.com/channel/UCtOCVMQqeIit1b924apLv1w" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/brand/huytihon.png" alt="Huy Ti Hon"/>
                        </a>                        
                    </li>     

                    <li>
                        <a href="https://www.youtube.com/channel/UCZfRMlF3GLrsASnY3wY4I8g" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/brand/utmini.png" alt="Ut Mini"/>
                        </a>                        
                    </li>                     
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;