import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderTwo from "../component/slider/SliderTwo";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Team from "../elements/Team";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import BlogContent from "../elements/blog/BlogContent";
import { Translation } from 'react-i18next';
import i18n from "i18next";

class MainDemo extends Component{
    constructor(props) {
        super(props);
    
        if(this.props.id==="en" || this.props.id==="vi"){
            i18n.changeLanguage(this.props.id)
        }
        
    }
    
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <Fragment> 
                <Helmet pageTitle="Trang chủ" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>                
                {/* Start Slider Area   */}
                <div className="slider-wrapper">                    
                    <SliderTwo />
                    
                </div>
                
                {/* End Slider Area   */}
                                        

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}
                
                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center" >
                                    <h2 className="section-title"  style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('ourFacts')}</Translation></h2>
                                    <p className="section-title"  style={{fontFamily:"Helvetica", marginTop:20}}><Translation>{(t, { i18n }) => t('ourFactsDesc')}</Translation></p >
                                </div>
                            </div>                            
                        </div>                    
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}
                
                {/* Start Service Area   */}
                <div className="service-area ptb--80  bg_image bg_image--3" >
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
                {/* End Service Area   */}
                
                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0" >
                                    <h2 className="title" style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('teamTit')}</Translation></h2>
                                    <p style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('teamDes')}</Translation></p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}      

                {/* Start Blog Area */}
                <div className="rn-blog-area pt--120 bg_color--1 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-left">
                                    <h2 style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('latestNews')}</Translation></h2>
                                    <p style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('latestNewsDes')}</Translation></p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                                    <a className="btn-transparent rn-btn-dark" href="/news"><span className="text" style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('viewAllNews')}</Translation></span></a>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="/">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="News Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title" style={{fontFamily:"Helvetica"}}><a href={value.link} target="_blank" rel="noopener noreferrer">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={value.link} style={{fontFamily:"Helvetica"}} target="_blank" rel="noopener noreferrer"><Translation>{(t, { i18n }) => t('readMore')}</Translation></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
                {/* End Blog Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <div id="contact">
                    <Footer />
                </div>                
            </Fragment>
        )
    }
}
export default MainDemo;