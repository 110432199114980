import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { Translation } from 'react-i18next';

let TeamContent = [
    {
        images: '01',
        title: 'Doan Nhu Phu',
        designation: <Translation>{(t, { i18n }) => t('phuDes')}</Translation>,
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/doclabinhduong.np'
            },
            {
                icon: <FaInstagram />,
                url: 'https://www.instagram.com/mrnhuphu/'
            },
            {
                icon: <FaTwitter />,
                url: 'https://twitter.com/NhuPhu2'
            },
        ]
    },
    {
        images: '02',
        title: 'Bien Tan Man',
        designation: <Translation>{(t, { i18n }) => t('manDes')}</Translation>,
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/people/Man-Bien-Tan/100001839542318'
            },
            {
                icon: <FaInstagram />,
                url: 'https://www.instagram.com/manbientan/'
            },
        ]
    },
    {
        images: '03',
        title: 'Nguyen Duc Trung',
        designation:  <Translation>{(t, { i18n }) => t('trungDes')}</Translation>,
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/people/Trung-Sad/100009096735715'
            },
            {
                icon: <FaInstagram />,
                url: 'https://www.instagram.com/kotinhyeu_hn/'
            },
            {
                icon: <FaTwitter />,
                url: 'https://mobile.twitter.com/sad_trung'
            },
        ]
    },
    {
        images: '04',
        title: 'Nguyen Van Linh',
        designation: <Translation>{(t, { i18n }) => t('linhDes')}</Translation>,
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: 'https://mobile.twitter.com/linhx_nguyen'
            },
        ]
    },
    {
        images: '05',
        title: 'Nguyen The Hieu',
        designation: <Translation>{(t, { i18n }) => t('hieuDes')}</Translation>,
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: 'https://www.facebook.com/people/Nguy%E1%BB%85n-Hi%E1%BA%BFu/100004051605607'
            },            
        ]
    },
    {
        images: '06',
        title: 'Huy Ti Hon',
        designation: <Translation>{(t, { i18n }) => t('hocvienDes')}</Translation>,
        socialNetwork: [
            {
                icon: <FaYoutube />,
                url: 'https://www.youtube.com/channel/UCtOCVMQqeIit1b924apLv1w'
            },            
        ]
    },
    {
        images: '07',
        title: 'Ut Mini',
        designation: <Translation>{(t, { i18n }) => t('hocvienDes')}</Translation>,
        socialNetwork: [
            {
                icon: <FaYoutube />,
                url: 'https://www.youtube.com/channel/UCZfRMlF3GLrsASnY3wY4I8g'
            },            
        ]
    },
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <picture>
                                    <source srcSet={`/assets/images/team/team-${value.images}.webp`} type="image/webp"/>
                                    <source srcSet={`/assets/images/team/team-${value.images}.jpg`} type="image/jpeg"/> 
                                    <img src={`/assets/images/team/team-${value.images}.jpg`} alt={value.title}/>
                                </picture>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation" style={{fontFamily:"Helvetica"}}>{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`} target="_blank" rel="noopener noreferrer">{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;