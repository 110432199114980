// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";


// Home layout
import MainDemo from './home/MainDemo';
import About from "./elements/About";
import error404 from "./elements/error404";
import Blog from "./elements/Blog";
import NewsDetails from "./elements/news/NewsDetails";
import DlvnOpening from "./elements/news/DlvnOpening";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "Welcome to React": "Welcome to React and react-i18next",
          "home" : "Home",
          "about" : "About",          
          "aboutDes" : "Less than one year, we discovered many characters with special, amazing talents and produced hundreds of viral videos on Doc La Binh Duong Youtube Channel and Facebook fanpage. There are videos that reach up to tens of millions of viewers. We transform normal persons to stars in social networks and fans love them. We connect with branding partners and spread their products to millions of people.",
          "aboutBrand" : "Our brands",
          "aboutBrandDes1" : "Độc Lạ Bình Dương (ExtraOrdinary Binh Duong): extraordinary / amazing stories that produced in Nhu Phu personal style.",
          "aboutBrandDes2" : "Độc Lạ Việt Nam (ExtraOrdinary Viet Nam): we depict extraordinary stories in the journalism style, aiming to produce documentaries for media distributors.",
          "aboutBrandDes3" : "Trải Nghiệm Mới (New Experiences): specializing in visting tourist attractions/restaurants and tell the experience to audiences.",
          "aboutBrandDes4" : "Út Mini, Huy Tí Hon: two new Youtube channels from our first talented creators",         
          "contact" : "Contact",
          "contactS" : "Contact for amazing stories",
          "contacth2" : "Let's talk to us",
          "connect" : "Connect to us",
          "service" : "Services",
          "view" : "Click to watch",
          "interactMil" : "Interact to millions",
          "email" : "Our emails",
          "biz" :"Business-Collaboration",
          "tel" : "Telphone numbers",
          "adr" : "Address",
          "adrLine0" : "ExtraOrdinary VietNam Company LTD",
          "adrLine" : "58, Street 5, Phu Thinh Villa, Ward Phu Tho, Thu Do Mot City, Binh Duong Province, Viet Nam",
          "lang" : "English",
          "news" : "News" ,
          "readMore" : "Read more",
          "viewAllNews" : "View All News",
          "latestNews" : "Latest News",
          "latestNewsDes" : "View the latest news and headlines about our work and activities",
          "s1Title": "Connecting",
          "s1Content": "ExtraOrdinary Viet Nam is constructing the most exciting video collection that includes rare, amazing, and real life stories.",
          "s2Title": "Talent",
          "s2Content": "Our expertise is finding exceptional talent inside normal persons and transform them to stars. Thanks to brands collaboration, we facilitate  character's life, to be better and happier.",
          "s3Title": "Popularity",
          "s3Content": "Everyday, our Youtube channels and Facebook pages are broadcasting amazing, exceptional life stories to millions of Vietnamese people and others in the world.",
          "pTitle" : "Extra-ordinary stories",
          "pContent" : "Through telling amazing stories, we introduce new life views which are miracle and colorful. Along with our sense of humour, we produce extra-ordinary content videos plus highly entertainment value.",
          "animal" : "Exceptional Animals",
          "tree" : "Amazing Trees",
          "human" : "ExtraOridinary Humans",
          "story" : "Unbelivable Stories",
          "p1Title" : "The dog walks by 2 legs as beautiful as a supermodel",          
          "p2Title" : "Visit Saigon with the smallest 19 year-old girl in VietNam",
          "p3Title" : "A 65 year old woman teaches dancing for her 24 year old husband",
          "p4Title" : "Pricing 100000 USD for a Vietnam bonsai tree",
          "p5Title" : "A miniature pig turns to a giant after two years",
          "ourFacts" : "Our Fun Facts",
          "ourFactsDesc" : "Monthly data in 2022 ",
          "fact1" : "Monthly views from our Youtube channels and Facebook fanpages",
          "fact2" : "Subscribers, followers in our Youtube channels and Facebook fanpages (to 12/2022)",
          "fact3" : "Monthly hours that audiences spent to watch our videos in Youtube channels and Facebook fanpages",
          "svDes" : "We produce videos in a unique, and humorous style to depict amazing stories that delight the hundreds of millions of viewers around the world.",
          "sv1Tit": "Brand promotion",
          "sv1Des": "Promote/Review your products to millions through our community on social network: Youtube, Facebook.",
          "sv2Tit": "Video content provider",
          "sv2Des": "We provide our copyright videos to broadcasting services and news distributor",
          "sv3Tit": "Talent seeking and developing",
          "sv3Des": "We find inner talents inside normal people and transform them to social network star",
          "sv4Tit": "ExtraOrdinary Shop",
          "sv4Des": "Spreading life inspiration stories and a new source of income for our amazing characters which discovered by the company",
          "teamTit" : "Skilled Team",
          "teamDes" : "The company is formed by a group of experts in journalism, media, advertising, information technology and video production",
          "phuDes" : "Founder, CEO, Directors",
          "manDes" : "Public Relations Advisor",
          "trungDes" : "Media Production Lead",
          "linhDes" : "IT Expert, Technical & Strategy Advisor",
          "hieuDes" : "Legal Advisor",
          "hocvienDes" : "Student",
          "vision" : "Our Vision",
          "visionDes" : "Constructing the most amazing video library in Vietnam that includes extraordinary, amazong stories in life",
          "mission" : "Our Mission",
          "missionDes" : "Through extraordinary life stories, we want to bring new life perspective. In order to sympathize and love others more",
          "value" : "Our Value",
          "valueDes" : "Honest: respect the truth, characters differences in producing videos, high credibility with partners. Dedication: not afraid of hardship to find the best stories",
        }
      },
      vi: {
        translation: {
          "Welcome to React": "Xin chào react",
          "home" : "Trang chủ",
          "about" : "Giới thiệu",
          "aboutDes" : "Chỉ trong vòng 1 năm chúng tôi đã phát hiện hàng loạt nhân vật có tố chất đặc biệt  và sản xuất hàng trăm video thú vị phát trên kênh Youtube và Fanpage: Độc Lạ Bình Dương. Có những video chạm tới hàng chục triệu người xem. Chúng tôi biến những nhân vật bình dân thành “ngôi sao độc lạ” được cộng đồng yêu mến. Chúng tôi kết nối họ với những đối tác và đưa thương hiệu của đối tác lan tỏa, được hàng triệu người biết đến.",
          "aboutBrand" : "Các kênh của chúng tôi",
          "aboutBrandDes1" : "Độc Lạ Bình Dương: tập hợp những video độc lạ mang phong cách cá nhân của Như Phú.",
          "aboutBrandDes2" : "Độc Lạ Việt Nam: tập hợp các video nội dung độc lạ, thú vị với lối thể hiện chỉnh chu để cung cấp cho đơn vị truyền thông, báo chí, nhà đài.",
          "aboutBrandDes3" : "Trải Nghiệm Mới: chuyên trải nghiệm đánh giá các điểm du lịch, các quán ăn ngon.",
          "aboutBrandDes4" : "Út Mini, Huy Tí Hon: hai kênh Youtube mới của hai học viên tài năng đầu tiên của công ty",         
          "contact" : "Liên hệ",
          "contactS" : "Thông tin liên hệ chuyện độc lạ",
          "contacth2" : "Liên lạc với chúng tôi",
          "connect" : "Liên Kết",
          "service" : "Dịch vụ",
          "view" : "Xem ngay",
          "interactMil" : "Tương tác với hàng triệu người",
          "email" : "Thư điện tử",
          "biz" :"Kinh Doanh - Hợp Tác",
          "tel" : "Số điện thoại",
          "adr" : "Địa chỉ",
          "adrLine0" : "Công ty TNHH Độc Lạ Việt Nam",
          "adrLine" : "Số 58, Đường số 5, Khu biệt thự Phú Thịnh, Phường Phú Thọ, Thành phố Thủ Dầu Một, Tỉnh Bình Dương",
          "lang" : "Tiếng Việt",
          "news" : "Tin tức" ,
          "readMore" : "Xem thêm",
          "viewAllNews" : "Toàn bộ tin tức",
          "latestNews" : "Tin mới nhất",
          "latestNewsDes" : "Thông tin mới nhất về công việc và các hoạt động của chúng tôi",
          "s1Title": "Kết nối",
          "s1Content": "Độc Lạ Việt Nam kiến tạo thư viện video thú vị nhất với 1001 câu chuyện độc lạ, hy hữu, có thật trong cuộc sống.",
          "s2Title": "Tài năng",
          "s2Content": "Công ty chúng tôi chuyên “đãi cát tìm vàng”, tìm những cá thể độc lạ và biến họ thành “ngôi sao”. Thông qua việc cộng tác với các thương hiệu, chúng tôi cải thiện cuộc sống cho nhân vật độc lạ, nghèo khó.",
          "s3Title": "Lan Tỏa",
          "s3Content": "Với các kênh youtube triệu view và fanpage tương tác cao, chúng tôi đang lan tỏa các câu chuyện thú vị đến hàng ngàn người Việt Nam mỗi phút.",
          "pTitle" : "Chuyện độc lạ",
          "pContent" : "Dựa trên việc kể những câu chuyện độc lạ, chúng tôi khai mở tri thức, giúp mọi người nhận ra thế giới quanh họ thật kỳ lạ, nhiệm màu. Cùng với phong cách  hài hước, chúng tôi sản xuất ra những video nội dung độc lạ nhưng đầy tính giải trí.",
          "animal" : "Động vật phi thường",
          "tree" : "Cây độc đáo",
          "human" : "Con người đặc biệt",
          "story" : "Câu chuyện khó tin",
          "p1Title" : "Su xí xọn, cô chó đi 2 chân đẹp như siêu mẫu",
          "p2Title" : "Cô nàng bé bỏng nhất Việt Nam ngao du Sài Gòn",
          "p3Title" : "Cụ bà Đồng Nai 65 tuổi dạy nhảy cho chồng 24 tuổi",
          "p4Title" : "Cây cảnh độc đáo nhất Việt Nam, hô bán 2 tỷ VND",
          "p5Title" : "Sau 2 năm sinh sống cùng heo trong phòng máy lạnh, cô chủ xinh đẹp cưới chồng",
          "ourFacts" : "Số liệu cụ thể",
          "ourFactsDesc" : "Số liệu hàng tháng trong năm 2022",
          "fact1" : "Số lượt xem videos hàng tháng trên Youtube và Facebook",
          "fact2" : "Số người theo dõi kênh trên Youtube và Facebook (tính đến tháng 12/2022)",
          "fact3" : "Số giờ xem hàng tháng videos trên Youtube và Facebook",
          "svDes" : "Chúng tôi sản xuất ra 1001 video theo phong cách chân thực, độc lạ, hài hước mang đến sự thú vị cho hàng trăm triệu người xem trên toàn thế giới",
          "sv1Tit": "Quảng bá thương hiệu",
          "sv1Des": "Đưa thông tin sản phẩm đến hàng triệu người thông qua các mạng xã hội như Youtube, Facebook.",
          "sv2Tit": "Cung cấp nội dung video",
          "sv2Des": "Cung cấp các video bản quyền về các câu chuyện độc lạ cho các nhà đài, hãng thông tấn trong và ngoài nước.",
          "sv3Tit": "Phát hiện và phát triển tài năng",
          "sv3Des": "Chúng tôi biến những nhân vật bình dân thành “ngôi sao độc lạ” được cộng đồng yêu mến",
          "sv4Tit": "Shop Độc Lạ",
          "sv4Des": "Lan tỏa thông điệp nghị lực sống và tạo ra nguồn thu nhập mới cho các nhân vật độc lạ được khám phá bởi công ty",
          "teamTit" : "Nhân sự",
          "teamDes" : "Công ty tập hợp những người có kinh nghiệm lâu năm trong ngành báo chí, truyền thông, quảng cáo, công nghệ thông tin và sản xuất video.",
          "phuDes" : "Người sáng lập, Giám đốc, Đạo diễn",
          "manDes" : "Cố vấn truyền thông",
          "trungDes" : "Trưởng phòng sản xuất",
          "linhDes" : "Cố vấn kỹ thuật & chiến lược",
          "hieuDes" : "Cố vấn pháp lý",
          "hocvienDes" : "Học viên",
          "vision" : "Tầm nhìn",
          "visionDes" : "Kiến tạo thư viện video thú vị nhất Việt Nam với 1001 câu chuyện độc lạ, hy hữu, có thật trong cuộc sống",
          "mission" : "Sứ mệnh",
          "missionDes" : "Thông qua xem những video độc lạ, hài hước, chúng tôi mong muốn mọi người có những góc nhìn mới về cuộc sống. Để cảm thông, yêu đời, yêu người hơn",
          "value" : "Giá trị cốt lõi",
          "valueDes" : "Chân thực: tôn trọng sự thật khi sản xuất video, tôn trọng sự khác biệt của từng nhân vật, giữ chữ tín với đối tác . Dâng hiến: không ngại khó, ngại khổ, phải tìm tạo ra những video chất lượng nhất, phải làm việc đầy năng lượng để mang sự thú vị đến cho cộng đồng",
        }
      },
    },
    lng: "vi",
    fallbackLng: "vi",

    interpolation: {
      escapeValue: false
    }
  });

class Root extends Component{

    render(){
        return(
          <BrowserRouter basename={'/'}>
              <Switch>
                  <Route exact path={`${process.env.PUBLIC_URL}/`} component={MainDemo}/>  
                  <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/thongtin`} component={About}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/news`} component={Blog}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/tintuc`} component={Blog}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/news/news-example`} component={NewsDetails}/>
                  <Route exact path={`${process.env.PUBLIC_URL}/news/doclavietnam-khaitruong`} component={DlvnOpening}/>

                  <Route exact path="/:id" render={(props) => <MainDemo id={props.match.params.id}/>} />
                  <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                  <Route component={error404}/>

              </Switch>
          </BrowserRouter>
        )      
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();