import React, { Component } from "react";
import { FiCast  , FiUsers , FiVideo, FiShoppingCart } from "react-icons/fi";
import { Translation } from 'react-i18next';

const ServiceList = [
    {
        icon: <FiCast />,
        title:  <Translation>{(t, { i18n }) => t('sv1Tit')}</Translation>,
        description:  <Translation>{(t, { i18n }) => t('sv1Des')}</Translation>,
    },
    {
        icon: <FiVideo />,
        title:  <Translation>{(t, { i18n }) => t('sv2Tit')}</Translation>,
        description: <Translation>{(t, { i18n }) => t('sv2Des')}</Translation>,
    },
    {
        icon: <FiUsers />,
        title:  <Translation>{(t, { i18n }) => t('sv3Tit')}</Translation>,
        description: <Translation>{(t, { i18n }) => t('sv3Des')}</Translation>,
    },
    {
        icon: <FiShoppingCart />,
        title:  <Translation>{(t, { i18n }) => t('sv4Tit')}</Translation>,
        description: <Translation>{(t, { i18n }) => t('sv4Des')}</Translation>,
        url : "https://www.doclavietnam.net/",
    }
]

class ServiceTwo extends Component{
    render(){
        let title = <Translation>{(t, { i18n }) => t('service')}</Translation>;
        let description = <Translation>{(t, { i18n }) => t('svDes')}</Translation>;
        return(
            <React.Fragment>
                <div className="row" style={{fontFamily:"Helvetica"}}>
                    <div className="col-lg-4 col-12" >
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title" style={{fontFamily:"Helvetica"}} >{title}</h2>
                            <p >{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/#contact"><span className="text"><Translation>{(t, { i18n }) => t('contacth2')}</Translation></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.url?val.url: "/about"}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content" >
                                                <h3 className="title" style={{fontFamily:"Helvetica"}}>{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
