import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
//import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
//import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Team from "../elements/Team";

import { Translation } from 'react-i18next';

let ourText = [
    {
        title: <Translation>{(t, { i18n }) => t('mission')}</Translation>,
        content: <Translation>{(t, { i18n }) => t('missionDes')}</Translation>,
    },
    {
        title: <Translation>{(t, { i18n }) => t('vision')}</Translation>,
        content: <Translation>{(t, { i18n }) => t('visionDes')}</Translation>,
    },
    {
        title: <Translation>{(t, { i18n }) => t('value')}</Translation>,
        content: <Translation>{(t, { i18n }) => t('valueDes')}</Translation>,
    }
]
class About extends Component{
    render(){
        let title = <Translation>{(t, { i18n }) => t('about')}</Translation>;
        let description = <Translation>{(t, { i18n }) => t('aboutDes')}</Translation>;
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Về chúng tôi || About' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" position="/about"/>
                {/* Start Breadcrump Area */}
                <Breadcrumb title={title}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/aPhu.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title" style={{fontFamily:"Helvetica"}}>{title}</h2>
                                            <p className="description" style={{fontFamily:"Helvetica"}}>{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            {ourText.map((value, i) => (
                                                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">                                                    
                                                    <h3 key={i} className="title"  style={{fontFamily:"Helvetica"}}>{value.title}</h3>
                                                    <p key={i+1} style={{fontFamily:"Helvetica"}}>{value.content} </p>
                                                </div>
                                            </div>
                                            ))                                    
                                            }
                                                                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}
                
                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                    <div className="section-title" >
                        <h2 className="title text-center" style={{fontFamily:"Helvetica"}}>{<Translation>{(t, { i18n }) => t('aboutBrand')}</Translation>}</h2>
                        <ul>
                            <li><p className="description" style={{fontFamily:"Helvetica"}}>{<Translation>{(t, { i18n }) => t('aboutBrandDes1')}</Translation>}</p></li>
                            <li><p className="description" style={{fontFamily:"Helvetica"}}>{<Translation>{(t, { i18n }) => t('aboutBrandDes2')}</Translation>}</p></li>
                            <li><p className="description" style={{fontFamily:"Helvetica"}}>{<Translation>{(t, { i18n }) => t('aboutBrandDes3')}</Translation>}</p></li>
                            <li><p className="description" style={{fontFamily:"Helvetica"}}>{<Translation>{(t, { i18n }) => t('aboutBrandDes4')}</Translation>}</p></li>
                        </ul>
                    </div>
                        <div className="row">                        
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                 {/* Start CounterUp Area */}
                 <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="section-title"  style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('ourFacts')}</Translation></h2>
                                    <p className="section-title"  style={{fontFamily:"Helvetica", marginTop:20}}><Translation>{(t, { i18n }) => t('ourFactsDesc')}</Translation></p >
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area rn-finding-us bg_color--1">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content" style={{opacity:0.8}}>
                                <h4 className="theme-gradient"><Translation>{(t, { i18n }) => t('interactMil')}</Translation></h4>
                                <p><Translation>{(t, { i18n }) => t('s3Content')}</Translation></p>
                                <a className="rn-btn btn-white" href="/#contact"><Translation>{(t, { i18n }) => t('contacth2')}</Translation></a>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/about_statis.jpg" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0" >
                                    <h2 className="title" style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('teamTit')}</Translation></h2>
                                    <p style={{fontFamily:"Helvetica"}}><Translation>{(t, { i18n }) => t('teamDes')}</Translation></p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}                               

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About