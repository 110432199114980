import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser  } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";

class DlvnOpening extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Khai trương Độc Lạ Việt Nam'/>
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--8" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient" style={{fontFamily:"Helvetica"}}>Khai trương công ty <br /> Độc Lạ Việt Nam</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />20-06-2020</li>
                                        <li><FiUser />Nhu Phu, Duc Trung, Linh Nguyen</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p style={{fontFamily:"Helvetica"}}>Ngày 20-6, công ty TNHH Độc Lạ Việt Nam khai trương tại trụ sở số 9 đường số 5 Khu Biệt thự Phú Thịnh, phường Phú Thọ, TP. Thủ Dầu Một tỉnh Bình Dương.</p>
                                        <div className="thumbnail">
                                            <img src="/assets/images/blog/blog-dlvn_opening.jpg" alt="Khai truong Doc La Viet Nam"/>
                                        </div>
                                        <p className="mt--40" style={{fontFamily:"Helvetica"}}>Về tầm nhìn của công ty Độc Lạ Việt Nam, ông Đoàn Như Phú, Giám đốc công ty cho biết: “Chúng tôi sẽ kiến tạo thư viện video thú vị nhất Việt Nam với 1001 câu chuyện độc lạ, hy hữu, có thật trong cuộc sống”. Về sứ mệnh công ty, ông Đoàn Như Phú chia sẻ: “Thông qua xem những video độc lạ, hài hước, chúng tôi mong muốn mọi người có những góc nhìn mới về cuộc sống. Để cảm thông, yêu đời, yêu người hơn”.</p>
                                        <p style={{fontFamily:"Helvetica"}}>Ông Phú nói thêm: “Giá trị cốt lõi của công ty là chân thực và dâng hiến. Chân thực tức là tôn trọng sự thật khi sản xuất video, tôn trọng sự khác biệt của từng nhân vật, giữ chữ tín với đối tác. Dâng hiến nghĩa là không ngại khó, ngại khổ, phải tìm tạo ra những video chất lượng nhất, phải làm việc đầy lượng để mang sự thú vị đến cho cộng đồng”.</p>
                                        <blockquote className="rn-blog-quote" style={{fontFamily:"Helvetica"}}>Công ty Độc Lạ Việt Nam cung cấp 3 dịch vụ chính gồm: Quảng bá thương hiệu cho đối tác; cung cấp video thú vị cho nhà đài - cơ quan báo chí; Phát hiện và phát triển tài năng.</blockquote>
                                        <p style={{fontFamily:"Helvetica"}}>Đến dự buổi khai trương có những người bạn, đối tác đã hỗ trợ đắc lực cho công ty trong giai đoạn
                                        thành lập gồm ông Biện Tấn Dũng, Giám đốc công ty TNHH Tư vấn và Đầu tư Luật Trí Việt, nhà báo
                                        Dương Chí Tưởng thuộc Thông Tấn Xã Việt Nam, ông Lê Thanh Trung, Giám đốc công ty TNHH Truyền
                                        thông Bình Dương 24H, ông Võ Định Chiêu, đại diện tập đoàn BĐS Thiên An Holdings, chị Hớn Hở (cơ sở
                                        sản xuất dầu gội bồ kết), chị Phạm Ngọc Kim Ngân (vựa mắm Ngân Ú)…</p>                                        

                                        <p className="mt--25 mt_sm--5" style={{fontFamily:"Helvetica"}}>Dịp này Giám đốc công ty Độc Lạ Việt Nam gửi lời cảm ơn đến cộng sự đã đóng góp nhiều ý tưởng giúp
                                            công ty hoàn thiện chiến lược phát triển gồm anh Nguyễn Văn Lĩnh, Cố vấn kỹ thuật của công ty (đang
                                            làm việc tại Phần Lan), anh Biện Tấn Mân, Cố vấn truyền thông của công ty (nguyên PGĐ Trung tâm Dịch
                                            vụ Quảng cáo Đài Phát thanh – Truyền hình Bình Dương – BTV. Phó Tổng biên tập Tạp chí BTV).</p>
                                        <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/blog-dlvn_opening.jpg" alt="Khai truong Doc La Viet Nam"/>
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='e2JdS4XYyvc' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                            <span style={{fontFamily:"Helvetica"}}>Tin - ảnh: Nguyễn Đức Trung - Trưởng Phòng Sản xuất thuộc Công ty Độc Lạ Việt Nam</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default DlvnOpening;