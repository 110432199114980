import React, { Component } from "react";
import Slider from "react-slick";
import { slideSlick } from "../../page-demo/script";
import { Translation } from 'react-i18next';

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--33',
        category: '',
        title: <Translation>{(t, { i18n }) => t('s1Title')}</Translation>,
        description: <Translation>{(t, { i18n }) => t('s1Content')}</Translation>,        
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--34',
        category: '',
        title: <Translation>{(t, { i18n }) => t('s2Title')}</Translation>,
        description: <Translation>{(t, { i18n }) => t('s2Content')}</Translation>,
    },
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--35',
        category: '',
        title: <Translation>{(t, { i18n }) => t('s3Title')}</Translation>,
        description: <Translation>{(t, { i18n }) => t('s3Content')}</Translation>,
    }
]
class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation call-to-action ">            
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                {SlideList.map((value , index) => (
                    <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={`inner ${value.textPosition}`}>
                                        {value.category ? <span>{value.category}</span> : ''}
                                        {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                        {value.description ? <p className="description" style={{fontFamily:"Helvetica"}}>{value.description}</p> : ''}
                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
  }
}
export default SliderOne;