import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import { Translation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import i18n from "i18next";

class Header extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })

    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }


    render(){
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        }else{
            logoUrl = <img src="/assets/images/logo/logo-darkDLVN.png" alt="Digital Agency" />;
        }
        if(i18n.language==='en'){
            logoUrl = <img src="/assets/images/logo/logo-darkDLVNEN.png" alt="Digital Agency" />;
        }

        
        //    buttonLabel = i18n.language==="vi"?"English":"Tiếng Việt";        
        function handleClick(e) {
            e.preventDefault();
            i18n.language==="vi"?i18n.changeLanguage("en"):i18n.changeLanguage("vi");
        }

        return(
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu" style={{fontFamily:"Helvetica"}}>
                                <li><Link to="/" ><Translation>{(t, { i18n }) => t('home')}</Translation></Link></li>
                                <li><Link to="/about" ><Translation>{(t, { i18n }) => t('about')}</Translation></Link></li>
                                <li><Link to="/news" ><Translation>{(t, { i18n }) => t('news')}</Translation></Link></li>                                                                
                                <li><Link to="/#service" ><Translation>{(t, { i18n }) => t('service')}</Translation></Link></li>
                                <li><Link to="/#contact" ><Translation>{(t, { i18n }) => t('contact')}</Translation></Link></li>
                                <li><a  className="rn-btn"  href={this.props.position?"/about":"/"} onClick={handleClick}>
                                    <span>English/Tiếng Việt</span></a>
                                </li>
                            </ul>
                        </nav>
                        
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;