const BlogContent = [
    {
        images: 'sanDL',
        title:'Bỏ thực tại, đi săn chuyện độc lạ',
        category: 'Báo chí',
        link: 'https://tuoitre.vn/nhung-nguoi-dam-gia-tu-ky-luong-ky-cuoi-bo-thuc-tai-di-san-chuyen-doc-la-20210729213823277.htm',   
    },
    {
        images: 'ngaReDL',
        title:'Ngã rẻ Độc Lạ',
        category: 'Báo chí',
        link: 'https://nld.com.vn/cong-nghe/nga-re-doc-la-20210529211134436.htm',   
    },
    {
        images: 'nutVangYT',
        title:'Nguyên nhà báo nhận nút vàng Youtube',
        category: 'Báo chí',
        link: 'https://baotintuc.vn/doanh-nghiep-san-pham-dich-vu/nguyen-nha-bao-nhan-nut-vang-youtube-20210526115713184.htm',   
    },
    {
        images: 'congluan',
        title: 'Nhà báo thành Vlogger triệu view',
        category: 'Báo chí',
        link: 'https://congluan.vn/nha-bao-thanh-vlogger-trieu-view-post83413.html',
    },
    {
        images: 'dlvn_opening_thumbnail',
        title: 'Khai trương công ty Độc lạ Việt Nam',
        category: 'Công ty',
        link: 'https://www.doclavietnam.com/news/doclavietnam-khaitruong',
    },
    {
        images: 'shtt1',
        title: 'Báo Sở Hữu Trí Tuệ: Đoàn Như Phú - Người muốn kiến tạo thư viện video thú vị nhất Việt Nam',
        category: 'Báo chí',
        link: 'https://sohuutritue.net.vn/doan-nhu-phu--nguoi-muon-kien-tao-thu-vien-video-thu-vi-nhat-viet-nam-d77940.html',
    },
]

export default BlogContent;