import React, { Component } from "react";
import Slider from "react-slick";
import { Translation } from 'react-i18next';
import { portfolioSlick2 } from "../../../page-demo/script";


const PortfolioList = [
    {
        image: 'image-1',
        category: <Translation>{(t, { i18n }) => t('animal')}</Translation>,
        url: "https://youtu.be/YWSpmhXd6To",
        title: <Translation>{(t, { i18n }) => t('p1Title')}</Translation>,
    },
    {
        image: 'image-2',
        category: <Translation>{(t, { i18n }) => t('human')}</Translation>,
        url: "https://youtu.be/D_8e2Q4UY5E",
        title: <Translation>{(t, { i18n }) => t('p2Title')}</Translation>,
    },    
    {
        image: 'image-3',
        category: <Translation>{(t, { i18n }) => t('story')}</Translation>,
        url: "https://youtu.be/Po7TBS96h9c",
        title: <Translation>{(t, { i18n }) => t('p3Title')}</Translation>,
    },
    {
        image: 'image-1',
        category: <Translation>{(t, { i18n }) => t('animal')}</Translation>,
        url: "https://youtu.be/YWSpmhXd6To",
        title: <Translation>{(t, { i18n }) => t('p1Title')}</Translation>,
    },    
    {
        image: 'image-4',
        category: <Translation>{(t, { i18n }) => t('tree')}</Translation>,
        url: "https://youtu.be/WpG-gW8Gd28",
        title: <Translation>{(t, { i18n }) => t('p4Title')}</Translation>,
    },
    {
        image: 'image-5',
        category: <Translation>{(t, { i18n }) => t('animal')}</Translation>,
        url: "https://youtu.be/kd7SBpMaiXA",
        title: <Translation>{(t, { i18n }) => t('p5Title')}</Translation>,
    },    
    
]

class Portfolio extends Component{
    render(){
        let title = <Translation>{(t, { i18n }) => t('pTitle')}</Translation>,
        description = <Translation>{(t, { i18n }) => t('pContent')}</Translation>;
        return(
            <React.Fragment>
                <div className="portfolio-wrapper" style={{fontFamily:"Helvetica"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2 style={{fontFamily:"Helvetica"}}>{title}</h2>
                                    <p >{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 key={index}><a href={`${value.url}`} target="_blank" rel="noopener noreferrer">{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a className="rn-btn" href={`${value.url}`} target="_blank" rel="noopener noreferrer"><Translation>{(t, { i18n }) => t('view')}</Translation></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;