import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Độc Lạ Việt Nam || Extraordinary VietNam</title>
                    <meta name="description" content="Độc Lạ Việt Nam kiến tạo thư viện video độc lạ, hy hữu, có thật trong cuộc sống đến hàng triệu người xem thông qua các mạng xã hội Youtube và Facebook" />
                    <meta name="keywords" content="độc lạ Việt Nam, chuyện độc lạ, chuyện khó tin, chuyện lạ Việt Nam, độc đáo Việt Nam, ExtraOrdinary VietNam, Amazing VietNam" />
                    <meta property="og:title" content="Độc Lạ Việt Nam || Extraordinary VietNam"/>
                    <meta property="og:url" content="https://www.doclavietnam.com"/>
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="" />
                    <meta property="og:description" content="Độc Lạ Việt Nam kiến tạo thư viện video độc lạ, hy hữu, có thật trong cuộc sống đến hàng triệu người xem thông qua các mạng xã hội Youtube và Facebook"/>
                    <meta property="og:locale" content="vi_VN"/>
                    <meta property="og:locale:alternate" content="en_US" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
